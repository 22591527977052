import React, { useState, useEffect } from 'react';
import './App.css'; // Assuming you have an App.css file for custom styles

const App = () => {
  const [selectedItems, setSelectedItems] = useState({});
  const [bingo, setBingo] = useState(false);
  const [gridItems, setGridItems] = useState([]);

  useEffect(() => {
    // Randomize grid items on each refresh
    const randomizedItems = shuffleGridItems();
    setGridItems(randomizedItems);
  }, []);

  const shuffleGridItems = () => {
    const items = [
      'DRK grüßt nicht zurück', 'Doppel Transport', 'Pat. kann eig. Laufen', 'BW macht Rückspigel Rettung',
      'TS Falsch', 'ZNA PLW unfreundlich', 'ILS will essen', 'Mehr als 2 Koffer',
      'Müll ist noch da', 'Pat. wohnt Dachgeschoß', 'Halbes auto aufühlen', 'Pat. Unfreundlich',
      '3 mal LÖW', 'O2 leer', 'TK absaugen', 'Pat mehr als 150 kg',
    ];

    for (let i = items.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [items[i], items[j]] = [items[j], items[i]];
    }

    const gridItems = [];
    for (let i = 0; i < 4; i++) {
      gridItems.push(items.slice(i * 4, (i + 1) * 4));
    }

    return gridItems;
  };

  const checkBingo = (selectedItems) => {
    // Check rows
    for (let i = 0; i < gridItems.length; i++) {
      let rowBingo = true;
      for (let j = 0; j < gridItems[i].length; j++) {
        if (!selectedItems[gridItems[i][j]]) {
          rowBingo = false;
          break;
        }
      }
      if (rowBingo) {
        return true;
      }
    }

    // Check columns
    for (let i = 0; i < gridItems[0].length; i++) {
      let colBingo = true;
      for (let j = 0; j < gridItems.length; j++) {
        if (!selectedItems[gridItems[j][i]]) {
          colBingo = false;
          break;
        }
      }
      if (colBingo) {
        return true;
      }
    }

    // Check diagonals
    let diagonalBingo = true;
    for (let i = 0; i < gridItems.length; i++) {
      if (!selectedItems[gridItems[i][i]]) {
        diagonalBingo = false;
        break;
      }
    }
    if (diagonalBingo) {
      return true;
    }

    diagonalBingo = true;
    for (let i = 0; i < gridItems.length; i++) {
      if (!selectedItems[gridItems[i][gridItems.length - 1 - i]]) {
        diagonalBingo = false;
        break;
      }
    }
    if (diagonalBingo) {
      return true;
    }

    return false;
  };

  const handleClick = (item) => {
    setSelectedItems((prevState) => {
      const updatedState = { ...prevState, [item]: !prevState[item] };

      if (!bingo && checkBingo(updatedState)) {
        setBingo(true);
      }

      return updatedState;
    });
  };

  return (
    <div className="vh-100">
      {gridItems.map((row, rowIndex) => (
        <div key={rowIndex} className="grid-row">
          {row.map((item, itemIndex) => (
            <div
              key={itemIndex}
              className={`grid-item ${selectedItems[item] ? 'selected' : ''}`}
              onClick={() => handleClick(item)}
            >
              {item}
            </div>
          ))}
        </div>
      ))}
      {bingo && <div className="bingo-message">Du bist der KTW mit dem meisten pech im Landkreis.</div>}
    </div>
  );
};

export default App;
